export enum RECEPTION_TYPE {
  NONE      = -1,
  ERROR     = 0,
  SENSOR    = 1,
  ALARM     = 2,
  ACCESS    = 3,
  SYSTEM    = 4,
  AGREGATED = 5,
  ACK       = 6,
  SEQUENCE  = 7,
}

export function getReceptionTypes(): { value: number, name: string }[] {
  return Object.keys(RECEPTION_TYPE)
    .filter((key: any) => !isNaN(Number(RECEPTION_TYPE[key])))
    .map((key: any) => {
      return { value: Number(RECEPTION_TYPE[key]), name: key };
    });
}

export function getReceptionTypeByValue(valueToSeach: number, arr: { value: number, name: string }[]): string | undefined {

  let toret: string | undefined = '';

  toret = arr.find( item => item.value === valueToSeach )?.name;

  return toret ? toret : '-';
}
